// https://bl.ocks.org/hitarth19/0295f89b15da5ec03bc1a20644182ce8

import * as D3 from "d3"

export default (selection, props) => {
    const {
        data,
        width,
        height,
        margin,
        parseTimeFormat,
        lineClass,
        stroke,
        tooltipClass,
        mobile
    } = props;

    if (width === 0 || height === 0) {
        return;
    }

    selection.attr("transform", `translate(${margin.left},${margin.top})`);
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // data is expected to be an array where each element has a "date" and a "value" field
    const xValue = d => D3.timeParse(parseTimeFormat)(d.date);
    const yValue = d => d.value

    const xScale = D3.scaleTime()
        .domain(D3.extent(data, xValue))
        .rangeRound([0, innerWidth])

    const yScale = D3.scaleLinear()
        .domain([0, D3.max(data, yValue)])
        .rangeRound([innerHeight, 0]);

    const lineGenerator = D3.line()
        .x(d => xScale(xValue(d)))
        .y(d => yScale(yValue(d)))
        .curve(D3.curveBasis);

    const t = selection.transition()
        .duration(750);

    selection.selectAll(".x-axis")
        .data([null])
        .join(
            enter => enter.append("g")
                .attr("class", "x-axis")
                .call(D3.axisBottom(xScale))
                .attr("transform", `translate(0,${innerHeight + 2})`),
            update => update.transition(t)
                .call(D3.axisBottom(xScale))
                .attr("transform", `translate(0,${innerHeight + 2})`),
        );

    selection.selectAll(".y-axis")
        .data([null])
        .join(
            enter => enter.append("g")
                .attr("class", "y-axis")
                .call(D3.axisLeft(yScale))
                .attr("transform", `translate(-2,0)`),
            update => update.transition(t)
                .call(D3.axisLeft(yScale))
                .attr("transform", `translate(-2,0)`),
        );

    const getTooltipText = date => {
        const hovered = D3.timeMonth(date);
        let match;
        for (let i = 0; i < data.length; i++) {
            let x = D3.timeMonth(D3.timeParse(parseTimeFormat)(data[i].date));
            if (x.toDateString() === hovered.toDateString()) {
                match = {...data[i] };
                match.date = D3.timeFormat("%B %Y")(x);
                break;
            }
        }
        if (!match) {
            return "";
        }
        return `${match.date}: ${match.value}`;
    }

    let tooltipDiv = D3.select(`.${tooltipClass}`);
    const setupMouseHandler = selection => {
        if (!selection) {
            return;
        }
        selection
            .on("mouseover", () => {
                let s = tooltipDiv.transition()
                    .duration(200)
                    .style("opacity", .9);
                if (mobile) {
                    s.transition()
                    .duration(3000)
                    .style("opacity", 0)
                }
            })
            .on("mousemove", () => {
                const hovered = xScale.invert(D3.event.offsetX - margin.left);
                const txt = getTooltipText(hovered);
                tooltipDiv.html(txt)
                    .style("left", (D3.event.pageX) + "px")
                    .style("top", (D3.event.pageY - 28) + "px")
                }
            )
            .on("mouseout", () =>
                tooltipDiv.transition()
                    .duration(500)
                    .style("opacity", 0)
            );
    };

    selection.selectAll(`.${lineClass}`)
        .data([null])
        .join(
            enter => enter.append("path")
                .attr("class", lineClass)
                .attr("d", lineGenerator(data))
                .attr("stroke", stroke)
                .call(setupMouseHandler),
            update =>
                update.call(setupMouseHandler)
                    .transition(t)
                    .attr("d", lineGenerator(data))
        );
};
