import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import { Dropdown } from "semantic-ui-react"
import SEO from "../../../components/seo"
import * as D3 from "d3"
import useContainerDimensions from "../../../components/useContainerDimensions"
import dateLineChart from "../../../components/dateLineChart"
import styles from "./google-trends.module.css"
import "../../general.css"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Vegan Google trends";
let processedData;

export const query = graphql`
  query {
    allPlantBasedDietCsv {
      nodes {
        date: Month
        value: Plant_based_diet_worldwide
      }
    }
    allVeganismCsv {
      nodes {
        date: Month
        value: Veganism_worldwide
      }
    }
    allNonDairyCsv {
        nodes {
          date: Month
          value: non_dairy_worldwide
        }
      }
  }
`;

const Options = [
    { key: "v", text: "Veganism", value: "veganism" },
    { key: "p", text: "Plant based diet", value: "plantBasedDiet" },
    { key: "n", text: "Non dairy", value: "nonDairy" }
];

const GoogleTrends = ({ data, mobile }) => {
    let GraphAspectRatio = 4 / 3;
    if (mobile) {
        GraphAspectRatio = 1;
    }

    const [selectedTerm, setSelectedTerm] = useState("veganism");
    if (!processedData) {
        processedData = {}
        for (const k in data) {
            processedData[k] = { nodes: data[k].nodes.map(n => ({ ...n, value: +n.value })) }
        }
    }

    const key = `all${selectedTerm[0].toUpperCase()}${selectedTerm.substr(1)}Csv`;
    const selectedData = processedData[key].nodes;

    const onTermChanged = (event, result) => {
        let { value } = result || event.target;
        setSelectedTerm(value);
    };

    const graphDivRef = useRef();
    const { width } = useContainerDimensions(graphDivRef);
    const svgWidth = Math.max(width, 0);
    const svgHeight = Math.max(width / GraphAspectRatio, 0);
    const margin = {
        top: 10,
        right: 10,
        bottom: 30,
        left: 30
    }
    const renderChart = () => {
        if (typeof document === "undefined") {
            return;
        }
        const trendsSvg = D3.select(".trends");
        let trendsG = trendsSvg.select(".trendsG");
        if (trendsG.empty()) {
            trendsG = trendsSvg.append("g");
            trendsG.attr("class", "trendsG");
        }
        trendsG.call(dateLineChart, {
            data: selectedData,
            width: svgWidth,
            height: svgHeight,
            margin,
            parseTimeFormat: "%Y-%m",
            lineClass: styles.linePath,
            stroke: "#238B45",
            tooltipClass: styles.tooltip,
            mobile
        });
    };

    renderChart();
    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className="citation" style={{ textAlign: "center", marginBottom: 10 }}>
                        This graph shows relative interest in each search term over time.
                    </div>
                    <div className={styles.tooltip} style={{ opacity: 0 }} />
                    <div style={{ textAlign: "center", marginBottom: 10 }}>
                    Select a search term: {" "}
                    <Dropdown
                        upward
                        floating
                        inline
                        options={Options}
                        value={selectedTerm}
                        onChange={onTermChanged}
                    />
                </div>
                    <div className="FlexGrow" ref={graphDivRef} style={{ textAlign: "center" }}>
                        <svg className="trends" width={`${svgWidth}px`} height={`${svgHeight}px`} />
                    </div>
                    <div className="citation">
                        Data source: <a href={`https://trends.google.com/trends/explore?date=all&q=${Options.filter(o => o.value === selectedTerm)[0].text}`}>
                            Google trends</a><br />
                    </div>
                </div>
            </div>
        </>
    )
};

export default GoogleTrends;
